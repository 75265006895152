import React, {useEffect, useState} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {Redirect, Route, Switch, useHistory, useLocation} from "react-router-dom";

import {GlobalStyle} from "../style/GlobalStyles";
import {
    ADD_DOCUMENTS,
    ERROR_ROUTE,
    EXPIRED_ROUTE,
    getAvailableDocumentRoutes,
    getAvailableSignatureRoutes,
    getAvailableSignatureRoutesPoa,
    getErrorRoute,
    getExpiredRoute,
    getReplacedRoute,
    ONLINE_SIGN_POA_ROUTE,
    ONLINE_SIGN_ROUTE,
    REPLACED_ROUTE,
    SEND_POA
} from "../configs/routes";
import Loading from "../components/Loading";

import Header from "../components/Header";
import OnlineSignContainer from "../containers/OnlineSignContainer";
import UploadDocumentsContainer from "../containers/UploadDocumentsContainer";
import SendPoaContainer from "../containers/SendPoaContainer";
import DocumentsThanksContainer from "../containers/DocumentsThanksContainer";
import OnlineSignPoaContainer from "../containers/OnlineSignPoaContainer";
import OnlineSignThanksContainer from "../containers/OnlineSignThanksContainer";
import OnlineSignConfirmationContainer from "../containers/OnlineSignConfirmationContainer";
import OnlineSignPoaConfirmationContainer from "../containers/OnlineSignPoaConfirmationContainer";
import OnlineSignPoaThanksContainer from "../containers/OnlineSignPoaThanksContainer";
import ErrorContainer from "../containers/ErrorContainer";
import GET_CONTRACT from "../graphql/queries/getContract";
import GET_VIEWER from "../graphql/queries/getViewer";
import 'react-widgets/dist/css/react-widgets.css';
import {setLabelCommodity} from "../helpers/getLabel";
import {uniqueItems} from "../helpers/arrayUtils";
import hasParametersToFillBeforeSignature from "../helpers/hasParametersToFillBeforeSignature";
import OnlineSignParamsContainer from "./OnlineSignParamsContainer";
import Footer from "../components/Footer";
import { logPageViewEvent, registerEventHandlers } from '../utils/analytics';
import ExpiredConatiner from './ExpiredContainer';
import ReplacedConatiner from './ReplacedConatiner';

const DOCUMENT_REQUEST_EXPIRED_STATE = 'expired';
const DOCUMENT_REQUEST_CANCELED_STATE = 'canceled';
const DOCUMENT_REQUEST_NON_VALID_STATE = 'non_valid';

const route = (path, Component, viewer) => ({[path]: <Route key={path} path={path} render={props => <Component {...props} viewer={viewer} />}/>});

const getFilteredRoutesArray = (allRoutes, allowedRoutes) => {
    let filteredRoutes = [];
    Object.keys(allRoutes).forEach(key => {
        if(allowedRoutes.includes(key)) {
            filteredRoutes.push(allRoutes[key]);
        }
    });
    return filteredRoutes;
}

const getAllRoutes = (viewer) => Object.assign({},
    route(`/${ERROR_ROUTE}`, ErrorContainer),
    route(`/${EXPIRED_ROUTE}`, ExpiredConatiner),
    route(`/${REPLACED_ROUTE}`, ReplacedConatiner),

    route(`/doplneni-udaju`, OnlineSignParamsContainer, viewer),

    route(`/${ONLINE_SIGN_ROUTE}/1`, OnlineSignContainer, viewer),
    route(`/${ONLINE_SIGN_ROUTE}/2`, OnlineSignContainer, viewer),
    route(`/${ONLINE_SIGN_ROUTE}/3`, OnlineSignContainer, viewer),
    route(`/${ONLINE_SIGN_ROUTE}/4`, OnlineSignContainer, viewer),
    route(`/${ONLINE_SIGN_ROUTE}/5`, OnlineSignContainer, viewer),
    route(`/${ONLINE_SIGN_ROUTE}/6`, OnlineSignContainer, viewer),
    route(`/${ONLINE_SIGN_ROUTE}-potvrzeni`, OnlineSignConfirmationContainer, viewer),
    route(`/${ONLINE_SIGN_ROUTE}-dekujeme`, OnlineSignThanksContainer, viewer),



    route(`/${ADD_DOCUMENTS}`, UploadDocumentsContainer, viewer),
    route(`/${SEND_POA}`, SendPoaContainer, viewer),
    route(`/kompletni`, DocumentsThanksContainer, viewer),


    route(`/${ONLINE_SIGN_POA_ROUTE}/1`, OnlineSignPoaContainer, viewer),
    route(`/${ONLINE_SIGN_POA_ROUTE}/2`, OnlineSignPoaContainer, viewer),
    route(`/${ONLINE_SIGN_POA_ROUTE}/3`, OnlineSignPoaContainer, viewer),
    route(`/${ONLINE_SIGN_POA_ROUTE}-potvrzeni`, OnlineSignPoaConfirmationContainer, viewer),
    route(`/${ONLINE_SIGN_POA_ROUTE}-dekujeme`, OnlineSignPoaThanksContainer, viewer),
);

const App = () => {
        const history = useHistory();
        const location = useLocation();
        const {loading, error, data} = useQuery(GET_CONTRACT);
        const {data: dataViewer, error: viewerError} = useQuery(GET_VIEWER);
        const [allowedRoutes, setAllowedRoutes] = useState([]);

        const isDocumentRequestReplaced = data && data.contract && data.contract.activeDocumentRequest && data.contract.activeDocumentRequest.nextDocumentRequestId != null;
        const isDocumentRequestCanceled = data && data.contract && data.contract.activeDocumentRequest && data.contract.activeDocumentRequest.state === DOCUMENT_REQUEST_CANCELED_STATE;
        const isDocumentRequestExpired = data && data.contract && data.contract.activeDocumentRequest && data.contract.activeDocumentRequest.state === DOCUMENT_REQUEST_EXPIRED_STATE;
        const isDocumentRequestNonValid = data && data.contract && data.contract.activeDocumentRequest && data.contract.activeDocumentRequest.state === DOCUMENT_REQUEST_NON_VALID_STATE;

        useEffect(() => {
            registerEventHandlers();
        }, []);

        useEffect(() => {
            if (isDocumentRequestExpired) {
                setAllowedRoutes(getExpiredRoute());
            } else if (isDocumentRequestReplaced) {
                setAllowedRoutes(getReplacedRoute());
            } else if (isDocumentRequestCanceled || isDocumentRequestNonValid) {
                setAllowedRoutes(getErrorRoute());
            } else if (data && dataViewer) {    
                let allowedRoutesForViewer = uniqueItems([
                    ...getAvailableSignatureRoutes({
                        role: dataViewer.viewer.type,
                        signatureState: data.contract.activeDocumentRequest.signatureState,
                        hasParametersToFill: hasParametersToFillBeforeSignature(data.contract.activeDocumentRequest),
                    }),
                    ...getAvailableDocumentRoutes({
                        role: dataViewer.viewer.type,
                        documentsState: data.contract.activeDocumentRequest.documentsState,
                        signatureState: data.contract.activeDocumentRequest.signatureState
                    }),
                    ...getAvailableSignatureRoutesPoa({
                        role: dataViewer.viewer.type,
                        signatureState: data.contract.activeDocumentRequest.signatureState,
                        hasData: data.contract.activeDocumentRequest?.onlinePoa?.hasData
                    }),
                ]);

                if (allowedRoutesForViewer) {
                    let routeIndex = allowedRoutesForViewer.findIndex(item => location.pathname === `${item}`);

                    if (routeIndex < 0) {
                        console.log(location.pathname, "not found among", allowedRoutesForViewer);
                        return history.push(`${allowedRoutesForViewer[allowedRoutesForViewer.length > 1 ? 1 : 0]}`);
                    }

                    logPageViewEvent();

                    setAllowedRoutes(allowedRoutesForViewer);
                }
            }
        }, [location, data, dataViewer, isDocumentRequestReplaced, isDocumentRequestCanceled, isDocumentRequestExpired]); // eslint-disable-line

        if (loading) return <Loading/>;

        if (isDocumentRequestReplaced) {
            return (
                <>
                    <GlobalStyle/>
                    <Header requestType={data?.contract?.activeDocumentRequest?.type}/>
                    <Route path={`/${REPLACED_ROUTE}`} component={ReplacedConatiner}/>
                    <Footer/>
                    <Redirect to={`/${REPLACED_ROUTE}`}/>
                </>
            );
        }

        if (isDocumentRequestExpired) {
            return (
                <>
                <GlobalStyle/>
                <Header requestType={data?.contract?.activeDocumentRequest?.type}/>
                <Route path={`/${EXPIRED_ROUTE}`} component={ExpiredConatiner}/>
                <Footer/>
                <Redirect to={`/${EXPIRED_ROUTE}`}/>
                </>
            );
        }

        if (error || viewerError || isDocumentRequestCanceled || isDocumentRequestNonValid) return <>
            <GlobalStyle/>
            <Header requestType={data?.contract?.activeDocumentRequest?.type}/>
            <Route path={`/${ERROR_ROUTE}`} component={ErrorContainer}/>
            <Footer/>
            <Redirect to={`/${ERROR_ROUTE}`}/>
        </>;

        setLabelCommodity(data.contract.commodity);

        if (allowedRoutes.length === 0) {
            return null;
        }

        return (
            <>
                <GlobalStyle/>
                <Header requestType={data?.contract?.activeDocumentRequest?.type}/>
                <Switch>
                    {getFilteredRoutesArray(getAllRoutes(dataViewer.viewer), allowedRoutes)}
                </Switch>
                <Footer/>
            </>
        );
    }
;

export default App;
